// for radius

  .mdc-text-field--outlined {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing,
    .mdc-notched-outline__notch {
      border-color: $borderColor;
    }

    .mdc-notched-outline .mdc-notched-outline__leading {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    .mdc-notched-outline .mdc-notched-outline__trailing {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  .mdc-form-field > label {
    font-size: 14px;
  }

  // set fix height to input

  // .mat-mdc-form-field {
  //   height: 44px;
  //   .mat-mdc-form-field-flex {
  //     height: 44px;
  //     align-items: center;
  //   }
  // }

  .hide-hint {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }


  // theme select
.theme-select {
  width: 125px;
  height: 36px;
  .mat-form-field-infix {
    padding: 6px 0 1px !important;
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border-color: $borderColor;
  }
  .mdc-text-field--outlined
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-color: $borderColor;
  }
  .mat-mdc-form-field-flex {
    height: 36px;
    align-items: center;
  }
}