$mat-primary: (
  100: white,
  500: #1e88e5,
  700: #1e88e5,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);
$mat-secondary: (
  100: white,
  500: #26c6da,
  700: #26c6da,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

$mat-warn: (
  100: FDEDE8,
  500: #e91e63,
  700: #e91e63,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

$theme-primary: mat.define-palette($mat-primary, 500);
$theme-accent: mat.define-palette($mat-secondary, 500);
$theme-warn: mat.define-palette($mat-warn, 500);

$bluetheme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography:
      mat.define-typography-config(
        $font-family: "Poppins",
        $subtitle-2: mat.define-typography-level(14px, 14px, 400),
        $subtitle-1: mat.define-typography-level(21px, 26px, 500),
         $headline-6: mat.define-typography-level(18px, 26px, 500),
        $headline-5: mat.define-typography-level(24px, 30px, 700),
        $button: mat.define-typography-level(14px, 14px, 400),
        $body-1: mat.define-typography-level(14px, 20px, 400),
        $body-2: mat.define-typography-level(16px, 22px, 400),
      ),
  )
);

$darkbluetheme: mat.define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography:
      mat.define-typography-config(
        $font-family: "Poppins",
        $subtitle-2: mat.define-typography-level(14px, 14px, 400),
        $subtitle-1: mat.define-typography-level(21px, 26px, 500),
         $headline-6: mat.define-typography-level(18px, 26px, 500),
        $headline-5: mat.define-typography-level(24px, 30px, 700),
        $button: mat.define-typography-level(14px, 14px, 400),
        $body-1: mat.define-typography-level(14px, 20px, 400),
        $body-2: mat.define-typography-level(16px, 22px, 400),
      ),
  )
);

.bg-primary {
  background: mat.get-color-from-palette($theme-primary, 500) !important;
}

.topbar {
  background: linear-gradient(135deg, #034583, #1e88e5) !important;
}

.topbar .mat-mdc-icon-button,
.topbar .mat-mdc-button {
  color: $white !important;
}

.horizontal-navbar .parentBox.pactive > a,
.horizontal-navbar .parentBox.pactive > a:hover,
.horizontal-navbar .parentBox.pactive:hover > a {
  background-color: mat.get-color-from-palette($theme-accent, 500) !important;
}

.activeMenu {
  background-color: mat.get-color-from-palette($theme-accent, 500) !important;
}


// outline button border
.mat-mdc-outlined-button {
  &.mat-primary{
    border-color: mat.get-color-from-palette($theme-primary, 500) !important;
  }
  &.mat-accent{
    border-color: mat.get-color-from-palette($theme-accent, 500) !important;
  }
  &.mat-warn{
    border-color: mat.get-color-from-palette($theme-warn, 500) !important;
  }
}